import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { UserService } from 'riterz-core';
import { ActivatedRoute, Router } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppService } from '../../services/app.service';
import { LoginCodeRequestComponent } from '../login-code-request/login-code-request.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
  ],
  providers: [DialogService],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  apiRoot: string = environment.riterz_api_url;
  returnUrl: string = encodeURIComponent(window.location.href);

  emailLogin: boolean = false;
  loggingIn: boolean = false;
  loadingGlobal: boolean = false;
  loadingInvitation: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private appService: AppService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private dialogService: DialogService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (this.dialogConfig.data?.returnUrl) {
      this.returnUrl = window.location.protocol + '//' + window.location.host + this.dialogConfig.data.returnUrl;
    }

    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
      password: new FormControl('', [
        Validators.required,
      ]),
      client_id: new FormControl(environment.riterz_client_id),
    });
  }

  ngOnInit() {
    const routeParamsSubscription = this.route.params
      .subscribe(
        (params) => {
          if (params['loginCode']) {
            this.loginWithCode(params['loginCode'])
          }
        }
      );
    this.subscriptions.push(routeParamsSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  login() {
    if (this.loginForm.invalid) { return; }

    this.loggingIn = true;
    this.userService
      .login(this.loginForm.value)
      .subscribe({
        next: (response) => {
          if (this.dialogConfig.data?.returnUrl) {
            this.router.navigateByUrl(this.dialogConfig.data.returnUrl);
          }

          this.dialogRef.close({ hasChanges: true });
          this.loggingIn = false;
        },
        error: (error) => {
          this.appService.showToast({ 
            severity: 'error', 
            summary: `Error`, 
            detail: error.message 
          });
          this.loggingIn = false;
        }
      });
  }

  requestLoginCode() {
    const modalRef = this.dialogService.open(LoginCodeRequestComponent, {
      header: 'Request login code',
      closable: true,
      dismissableMask: true,
      width: '40rem',
      data: {
        email: this.loginForm.get('email')?.value
      }
    });

    modalRef.onClose.subscribe((result) => {
      if (result?.hasChanges) {
        this.appService.showDialog({ 
          acceptLabel: 'Close',
          rejectVisible: false,
          header: `Login link`,
          message: `
            Login link has been sent to your email. If you haven't received
            it within a few minutes, please request a link again.
          `,
        });
        this.dialogRef.close({ hasChanges: false });
      }
    });
  }

  loginWithCode(code: string) {
    this.loadingGlobal = true;
    this.userService
      .loginWithCode(code)
      .subscribe({
        next: (response) => {
          this.dialogRef.close({ hasChanges: true });
          this.loadingGlobal = false;
        },
        error: (error) => {
          this.appService.showToast({ 
            severity: 'error', 
            summary: `Error`, 
            detail: error.message 
          });
          this.loadingGlobal = false;
        }
      });
  }

  toRegistration() {
    this.dialogRef.close({ openRegistration: true });
  }

}