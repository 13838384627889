import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { InvitationService, matchValidator, RecaptchaService, UserService } from 'riterz-core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppService } from '../../services/app.service';
import { WelcomeComponent } from '../welcome/welcome.component';

@Component({
  selector: 'app-registration',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
  ],
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registrationForm: FormGroup;
  invitation: any = null;
  apiRoot: string = environment.riterz_api_url;
  returnUrl: string = encodeURIComponent(window.location.href);
  termsUrl = environment.riterz_terms_url;
  recaptchaToken: string|null = null;

  tokenError: string|null = null;

  emailRegistration: boolean = false;
  registering: boolean = false;
  loadingGlobal: boolean = false;
  loadingInvitation: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private appService: AppService,
    private dialogService: DialogService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private userService: UserService,
    private recaptchaService: RecaptchaService,
    private invitationService: InvitationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    if (this.dialogConfig.data?.returnUrl) {
      this.returnUrl = window.location.protocol + '//' + window.location.host + this.dialogConfig.data.returnUrl;
    }

    this.registrationForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(/.*^(?=.{6,20})(?=.*[a-zA-Z])(?=.*[0-9]).*$/),
        matchValidator('confirm_password', true),
      ]),
      confirm_password: new FormControl('', [
        Validators.required,
        matchValidator('password'),
      ]),
      first_name: new FormControl('', [
        Validators.required
      ]),
      last_name: new FormControl('', [
        Validators.required
      ]),
      terms_and_conditions: new FormControl(false, [
        Validators.pattern('true')
      ]),
      client_id: new FormControl(environment.riterz_client_id),
    });
}

  ngOnInit() {
    // this.getRecaptcha();

    const token = this.route.snapshot.params['token'];
    if (token) {
      this.getInvitation(token);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getRecaptcha() {
    const action = 'register';

    this.recaptchaService
      .getRecaptcha(action)
      .subscribe({
        next: (token) => {
          this.recaptchaService
            .verifyRecaptcha(token, action)
            .subscribe({
              next: (response) => {
                this.recaptchaToken = token;
              },
              error: (error) => {
                this.appService.showToast({ 
                  severity: 'error', 
                  summary: `Error`, 
                  detail: error.message 
                });
              }
            });
        },
        error: (error) => {
          this.appService.showToast({ 
            severity: 'error', 
            summary: `Error`, 
            detail: error.message 
          });
        }
      });
  }

  register() {
    // if (!this.recaptchaToken) {
    //   // this.modalService.error({ 
    //   //   body: 'Recaptcha Error'
    //   // });
    //   return;
    // }

    this.registering = true;
    const params: any = {
      ...this.registrationForm.value,
    }
    if (this.route.snapshot.params['token']) {
      params.invitation_token = this.route.snapshot.params['token'];
    }
    this.userService
      .register(params)
      .subscribe({
        next: (response) => {
          if (this.dialogConfig.data?.returnUrl) {
            this.router.navigateByUrl(this.dialogConfig.data.returnUrl);
          }
          
          this.dialogRef.close({ hasChanges: true });
          this.dialogService.open(WelcomeComponent, {
            styleClass: 'rt-welcome',
            width: '36rem',
            data: {
              verified: false,
              email: this.registrationForm.controls['email'].value,
              name: this.registrationForm.controls['first_name'].value
            }
          });
          this.registering = false;
        },
        error: (error) => {
          this.appService.showToast({ 
            severity: 'error', 
            summary: `Error`, 
            detail: error.message 
          });
          this.registering = false;
        }
      });
  }

  getInvitation(token: string) {
    if (token) {
      const params = {
        include: 'organization'
      }
      this.loadingGlobal = true;
      this.invitationService
        .getInvitationByToken(token, params)
        .subscribe({
          next: (response) => {
            this.returnUrl = encodeURIComponent(window.location.origin + '/auth/registration/' + token);
            this.invitation = response.data;
            this.loadingGlobal = false;
          },
          error: (error) => {
            switch (error.status) {
              case 404:
                this.tokenError = 'Invalid invitation token!';
                break;
              case 410:
                this.tokenError = 'Expired invitation token!';
                break;
              default:
                this.tokenError = 'Invalid or expired invitation token!';
                break;
            }
            this.loadingGlobal = false;
          }
        });
    } else {
      this.tokenError = 'Invitation token missing!';
      this.invitation = null;
    }
  }

  toLogin() {
    this.dialogRef.close({ openLogin: true });
  }

}